import endpoint from "../constants/endPoints";
import { ILoginData } from "../interfaces/CommonInterface";
import ApiResponse from "../resources/entity/IApiResponse";
import * as http from "../utils/http";

/**
 * Login request
 */
export const login = (data?: ILoginData): Promise<ApiResponse> =>
  http.post(endpoint.auth.LOGIN, data);

/**
 * @description:- get user profile
 * @returns
 */
export const getProfile = (): Promise<ApiResponse> => {
  return http.get(endpoint.user.GET_PROFILE);
};

/**
 * @description:- login request
 * @returns
 */
export const isRead = (): Promise<ApiResponse> =>
  http.post(endpoint.user.IS_READ);